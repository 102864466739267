import Icon from "@/components/icon";
import Link from "next/link";
import { forwardRef } from "react";

interface ButtonProps {
  id?: string;
  onClick?: () => void;
  type: "button" | "submit" | "reset";
  label: string;
  variant: "primary" | "secondary" | "tertiary";
  size: string;
  shadow?: "uploadModal" | "button";
  disabled?: boolean;
  icon?: string;
  iconPosition?: "left" | "right";
  link?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id = "",
      label,
      onClick,
      disabled = false,
      icon = false,
      variant, // primary | secondary | tertiary
      iconPosition, // left || right,
      size, // small || large
      type = "button",
      shadow = "", //uploadModal || button
      link = null,
    },
    ref,
  ) => {
    const STYLES = {
      variant: {
        primary: "bg-Blue100 hover:bg-Blue200 active:bg-Blue300 px-6 font-bold",
        onlyIcon:
          "bg-Blue100 hover:bg-Blue200 active:bg-Blue300 p-2 rounded-xl",
        secondary:
          "bg-Black100 border-white ring-1 ring-inset ring-white hover:ring-Blue200 active:bg-Black200 px-6 font-bold",
        tertiary:
          "hover:bg-Black100 hover:border-white hover:ring-1 hover:ring-inset hover:ring-white active:ring-Blue200 active:bg-Black200 min-h-[40px] rounded-[16px] px-4 font-semibold",
      },
      size: {
        small: "text-[16px] py-2 leading-[24px]",
        medium: "text-[18px] py-2 leading-[24px] min-h-[48px]",
        large: "text-[24px] py-4 leading-[32px]",
      },
      iconPosition: {
        left: icon && "pl-4",
        right: icon && "flex-row-reverse pr-4",
      },
      shadow: {
        uploadModal: "shadow-uploadModal",
        button: "shadow-button",
      },
    };
    const button = (
      <button
        ref={ref}
        id={id}
        type={type}
        data-testid={id}
        className={`w-full h-full disabled:shadow-none whitespace-nowrap disabled:pointer-events-none flex items-center justify-center gap-x-2 font-redHatDisplay active:shadow-button text-white  rounded-2xl disabled:text-opacity-50 ${STYLES.size[size]} ${STYLES.variant[variant]} ${STYLES.iconPosition[iconPosition]} ${STYLES.shadow[shadow]}`}
        disabled={disabled}
        onClick={onClick}
      >
        {icon && <Icon size={24} name={icon} disabled={disabled} />}
        {label}
      </button>
    );
    return <>{link ? <Link href={link}>{button}</Link> : button}</>;
  },
);

Button.displayName = "Button";

export default Button;
