import * as Dialog from "@radix-ui/react-dialog";
import styles from "./modal.module.css";
import Button from "../button";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Modal = ({
  id,
  showModal,
  setShowModal,
  children,
  variant = "dialog", // dialog | upload | default | custom
  notHideClickOutside = false, //Si es true se bloquea que se cierre el modal al hacer click afuera
}) => {
  const [closing, setClosing] = useState(false);
  const MODALSTYLES = {
    variantBorder: {
      rest: "shadow-button ",
      custom: "shadow-uploadModal border-[1px] border-white",
    },
  };

  const closeAnimation = () => {
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      setShowModal(false);
    }, 200);
  };
  return (
    <Dialog.Root open={showModal} modal={true} onOpenChange={closeAnimation}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed  inset-0 bg-black bg-opacity-50 z-10" />
        <Dialog.Content
          onPointerDownOutside={(e) => {
            notHideClickOutside && e.preventDefault();
          }}
          className={`bg-Black200 fixed z-50 top-[50%] left-[50%] w-8/12 transform -translate-x-1/2 -translate-y-1/2 p-12  focus:outline-none rounded-[32px] ${
            closing ? styles.closing : styles.content
          }
          ${variant === "component" ? "" : " max-w-[735px]"}
          ${
            variant === "custom"
              ? MODALSTYLES.variantBorder.custom
              : MODALSTYLES.variantBorder.rest
          } 
         `}
          id={id}
          data-testid={id}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;

Modal.propTypes = {
  showModal: PropTypes.bool,
  variant: PropTypes.string,
};
