import React, { useEffect, useRef, useState } from "react";

import Icon from "../../../../components/icon";
import * as Label from "@radix-ui/react-label";
import { User } from "@/commons/interfaces/User";

interface Props {
  label: string;
  name: string;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  placeholder: string;
  allUsers: User[];
  setUser: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function Select({
  label,
  name,
  setError,
  placeholder,
  allUsers,
  setUser,
}: Props) {
  const [inputContent, setInputContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const containerInputRef = useRef(null);
  const inputRef = useRef(null);

  const handleOptionClick = (optionId) => {
    let userSelected = allUsers?.find((option) => option.id === optionId);
    setUser(userSelected.dni);

    let nameUserSelected =
      userSelected?.first_name + " " + userSelected?.last_name;
    setInputContent(nameUserSelected);
  };

  const eraseData = () => {
    inputRef.current.value = "";
    setUser(undefined);
    setInputContent(undefined);
    setIsOpen(false);
  };

  const inputChange = (e) => {
    setUser(e);
    setInputContent(e);
    setError("");
  };

  const openList = () => {
    setUser(undefined);
    setIsOpen(true);
  };

  useEffect(() => {
    //Funcion que detecta cuando haces click fuera del input
    const handleClickOutside = (event) => {
      if (
        containerInputRef.current &&
        !containerInputRef.current.contains(event.target)
      ) {
        // Realiza las acciones necesarias cuando se hace clic fuera del input
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div className="min-h-[92px] relative ">
      <Label.Root
        htmlFor={label}
        className="text-white font-normal text-[18px] leading-[24px]"
      >
        {label}
      </Label.Root>

      <div
        ref={containerInputRef}
        className="h-[64px] mt-[4px] ring-[2px] ring-white flex justify-between items-center py-[17px] px-[24px]  bg-Black100 text-white w-full rounded-3xl"
      >
        <input
          ref={inputRef}
          placeholder={placeholder}
          className="text-[22px] w-full bg-transparent focus:outline-none "
          type="text"
          name={name}
          id={name}
          autoComplete="webauthn"
          value={inputContent}
          onChange={(e) => {
            inputChange(e.target.value);
          }}
        />

        {inputContent ? (
          <Icon
            pointer={true}
            onClick={eraseData}
            name={"tag-cross4"}
            size={32}
          />
        ) : (
          allUsers?.length > 0 && (
            <Icon
              pointer={true}
              onClick={openList}
              name={"arrow-down-1"}
              size={32}
            />
          )
        )}
      </div>
      {isOpen && allUsers?.length > 0 && (
        <div
          className={`w-full  shadow-button mt-[8px] z-50 absolute overflow-y-scroll max-h-52 cursor-pointer bg-Black100 ring-2 ring-white text-white rounded-[25px]`}
        >
          <ul>
            {allUsers?.map((e) => (
              <li
                key={e.id}
                onClick={() => handleOptionClick(e.id)}
                className={
                  "hover:bg-Black200 bg-Black100 cursor-pointer border-b-2 h-full border-Black200 last:border-none "
                }
              >
                <div className="p-4 text-xl cursor-pointer">
                  <div className="flex justify-between w-full">
                    <p>
                      {e.first_name} {e.last_name} ({e.dni})
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
