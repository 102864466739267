import styles from "./styles.module.css";

import LOGO from "@/assets/logo/group.png";
import getConfig from "next/config";
import * as Avatar from "@radix-ui/react-avatar";
import { useEffect } from "react";
const { publicRuntimeConfig } = getConfig();
const LayoutAuth = ({ children, animation }) => {
  useEffect(() => {
    localStorage.setItem("isLogin", "false");
  }, []);

  return (
    <div className="flex flex-row h-screen bg-Blue100" data-testid="asd">
      <div
        className={`${
          animation ? styles.leftSide : "flex-1"
        } bg-BlueBG  flex flex-col justify-center items-center overflow-hidden`}
      >
        {publicRuntimeConfig && animation && (
          <p className="text-white font-semibold text-[20px] leading-[26px] mb-[32px]">
            v {publicRuntimeConfig.version}
          </p>
        )}
        <div className="p-8 bg-Black200 rounded-3xl text-white shadow-uploadModal  translate-x-[2px]">
          {children}
        </div>
      </div>

      <div
        className={
          "w-[475px] hidden  bg-Blue100 m-auto xl:flex flex-col justify-center items-center 2xl:w-[800px]"
        }
      >
        <Avatar.Root
          data-testid="avatar"
          className={`${animation ? styles.eppiText : "w-[154px]"} h-full`}
        >
          <Avatar.Image src={LOGO.src} alt="avatar" />
        </Avatar.Root>
      </div>
    </div>
  );
};

export default LayoutAuth;
