import * as Dialog from "@radix-ui/react-dialog";
import Modal from "..";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";
import Button from "@/components/button";
import Icon from "@/components/icon";

import { goBackVar } from "@/utils/variables";

interface Props {
  showCancelButton?: boolean;
  cancelLabel?: string;
  submitLabel: string;
  onClickSubmit: () => void;
  id?: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  showCloseIcon?: boolean;
  title: string;
  content: string;
  notHideClickOutside?: boolean;
}

const ModalDialog = ({
  showCancelButton = false,
  cancelLabel = "",
  submitLabel,
  onClickSubmit,
  id = "modal",
  showModal,
  setShowModal,
  showCloseIcon = false,
  title,
  content,
  notHideClickOutside = false, //Si es true se bloquea que se cierre el modal al hacer click afuera
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      id={id}
      notHideClickOutside={notHideClickOutside}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      {showCloseIcon && (
        <Dialog.Close
          className="bg-Black200 absolute z-50 right-[-76px] top-0 w-[68px] h-[68px] shadow-button rounded-2xl"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <Icon name="close-circle" size={48} />
        </Dialog.Close>
      )}

      <Dialog.Title className="text-white font-bold text-center text-3xl">
        {title}
      </Dialog.Title>

      <hr className="border-2 border-Blue100 rounded-sm my-4 mx-[100px]" />

      <Dialog.Description className="text-white mb-[26px] text-2xl text-center ">
        {content}
      </Dialog.Description>
      <div className="flex justify-end gap-4 mb-[10px]">
        {showCancelButton && (
          <Dialog.Close asChild>
            <Button
              label={cancelLabel ? cancelLabel : t("cancel")}
              icon="arrow-left-2"
              iconPosition={"left"}
              type="button"
              variant={"secondary"}
              size={"large"}
            />
          </Dialog.Close>
        )}
        <Dialog.Close asChild>
          <Button
            label={submitLabel}
            icon="arrow-right-34"
            iconPosition={"right"}
            onClick={() => {
              if (onClickSubmit) {
                onClickSubmit();
                goBackVar(null);
              }
            }}
            type="button"
            variant={"primary"}
            size={"large"}
          />
        </Dialog.Close>
      </div>
    </Modal>
  );
};

ModalDialog.propTypes = {
  showModal: PropTypes.bool,
  variant: PropTypes.string,
  showCancelButton: PropTypes.bool,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  onClickSubmit: PropTypes.func,
  setShowModal: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

export default ModalDialog;
