import React, { useRef, useState } from "react";

import * as Label from "@radix-ui/react-label";
import Icon from "../icon";
import { InputStatus } from "@/commons/use-cases/get-input-status";

export interface InputProps {
  label?: string;
  placeholder?: string;
  type?: "text" | "password" | "datetime-local" | "time";
  variant?: "primary" | "secondary" | "search";
  icon?: string;
  sizeIcon?: number;
  register?: (name: string, options: Record<string, any>) => any;
  name?: string;
  value?: string;
  clases?: string;
  classesInput?: string;
  error?: string;
  onChange?: (value: string | Date) => void;
  onBlur?: (value: string) => void;
  readOnly?: boolean;
  defaultValue?: string;
  id?: string;
  togglePasswordVisibility?: () => void;
  disabled?: boolean;
  warning?: string;
  step?: number;
  status?: InputStatus;
  min?: string;
  max?: string;
  registerOptions?: Record<string, any>;
}

export default function Input({
  label = "",
  placeholder = "",
  type = "text",
  variant = "primary",
  icon = "",
  sizeIcon = 20,
  register = null,
  name = "",
  value,
  clases = "",
  classesInput = "",
  error = "",
  onChange = (value: string | Date) => {},
  onBlur = (value: string) => {},
  readOnly = false,
  defaultValue = "",
  id = "",
  togglePasswordVisibility = () => {},
  disabled = false,
  warning = "",
  step = undefined,
  status,
  min,
  max,
  registerOptions = {},
}: InputProps) {
  const VALUE_STATUS_RING: Record<InputStatus | "disabled", string> = {
    error: "ring-Error hover:ring-Error",
    warning: "ring-Warning hover:ring-Warning",
    normal: "ring-white hover:ring-Blue100",
    disabled: "ring-white ring-opacity-50",
  };

  const TYPE_DIV = {
    primary: "h-[64px] py-[16px] px-[24px] rounded-3xl mt-[4px]",
    secondary: "h-[37px] py-[8px] px-[10px] rounded-lg mt-[2px]",
    search: "h-[37px] py-[8px] px-[10px] rounded-2xl mt-[2px]",
  };
  const TYPE_LABEL = {
    primary: "text-[18px]",
    secondary: "text-[14px] ",
    search: "text-[14px] ",
  };
  const TYPE_INPUT = {
    primary: "text-[22px]",
    secondary: "text-[16px] ",
    search: "text-[16px] placeholder-white",
  };
  const [onFocus, setOnFocus] = useState(false);
  const otherProps = register ? register(name, registerOptions) : {};

  let inputStatus = VALUE_STATUS_RING["normal"];
  if (status) {
    inputStatus = VALUE_STATUS_RING[status];
  } else if (disabled) {
    inputStatus = VALUE_STATUS_RING["disabled"];
  } else if (error) {
    inputStatus = VALUE_STATUS_RING["error"];
  }

  return (
    <div
      className={`w-full ${error?.length > 60 && "mb-5"} ${clases && clases}`}
    >
      {label && (
        <InputLabel
          label={label}
          variant={TYPE_LABEL[variant]}
          disabled={disabled}
        />
      )}
      <div
        className={`ring-[1px] ${
          inputStatus
        } flex flex-row justify-between items-center text-white ${
          TYPE_DIV[variant]
        }  ${onFocus ? "shadow-button bg-Black200" : " bg-Black100"}`}
      >
        <input
          disabled={disabled}
          placeholder={placeholder}
          data-testid={id}
          type={type}
          value={value}
          defaultValue={defaultValue}
          autoComplete="off"
          step={step}
          id={name}
          readOnly={readOnly}
          name={name}
          onFocus={() => setOnFocus(true)}
          onChange={(e) => {
            onChange && onChange(e.target.value);
          }}
          className={`w-full bg-transparent focus:outline-none ${
            disabled && "opacity-50"
          } ${TYPE_INPUT[variant]} calendar-icon ${classesInput && classesInput} `}
          min={min}
          max={max}
          {...otherProps}
          onBlur={(e) => {
            onBlur(e.target.value);
            setOnFocus(false);
          }}
        />
        {icon && (
          <Icon
            disabled={disabled}
            pointer={true}
            name={disabled ? "lock-1" : icon}
            size={sizeIcon}
            onClick={() => {
              !disabled && togglePasswordVisibility();
            }}
          />
        )}
      </div>
      <InputError disabled={disabled} error={error} warning={warning} />
    </div>
  );
}

interface InputLabelProps {
  label: string;
  variant?: string;
  disabled?: boolean;
}

export function InputLabel({ label, variant = "", disabled }: InputLabelProps) {
  return (
    <Label.Root
      className={`text-white font-normal leading-[24px] ${
        disabled && "opacity-50"
      } ${variant} `}
    >
      {label}
    </Label.Root>
  );
}

interface InputErrorProps {
  disabled?: boolean;
  error: string;
  warning?: string;
}

export function InputError({ disabled, error, warning = "" }: InputErrorProps) {
  return (
    <div className="relative">
      <p
        className={` ${disabled && "opacity-50"} absolute leading-[22px] text-white ${error?.length > 60 ? " h-2 " : "text-sm"}  `}
      >
        {error ? error : warning}
      </p>
    </div>
  );
}
