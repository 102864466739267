import React from "react";

const Icon = ({
  size = 20,
  name,
  color = "white",
  disabled = false,
  onClick = () => {},
  pointer = false,
  className = "",
}) => {
  return (
    <i
      data-testid={name}
      style={{ fontSize: size }}
      onClick={onClick}
      className={`icon text-${color} ${
        pointer && !disabled ? "cursor-pointer" : ""
      } isax isax-${name} ${disabled ? "text-opacity-50" : ""} ${className}`}
    ></i>
  );
};

export default Icon;
