import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query GetAllUsers($userRoles: JSON, $search: String) {
    getAllUsers(userRoles: $userRoles, search: $search) {
      id
      first_name
      last_name
      dni
      change_password_in_acccess
      last_access
      lock_state
      lock_date
      active
      roles {
        role {
          name
        }
      }
    }
  }
`;
export const GET_PARAMETERS = gql`
  query GetParametersQuery {
    getParameters {
      login_min_attemps_minutes
      logoff_inactivity_minutes
    }
  }
`;

export const LIST_USERS = gql`
  query ListUsers(
    $userRoles: JSON
    $page: Int
    $pageSize: Int
    $search: String
    $active: Boolean
    $order: String
  ) {
    listUsers(
      userRoles: $userRoles
      page: $page
      pageSize: $pageSize
      search: $search
      active: $active
      order: $order
    ) {
      page
      results {
        id
        first_name
        last_name
        active
        dni
        employee_number
        phone_number
        sector
        roles {
          role {
            machine_name
          }
        }
      }
      total
      totalPages
    }
  }
`;

export const GET_LAST_ACTIVITY = gql`
  query GetUser($getUserId: Int) {
    getUser(id: $getUserId) {
      last_activity
    }
  }
`;

export const UPDATE_LAST_ACTIVITY = gql`
  query UpdateLastActivity {
    updateLastActivity {
      id
    }
  }
`;
